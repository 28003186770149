import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Subfooter from "../components/subfooter";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Block from "../components/block";
import HeroTeam from "../components/heroTeam";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

function MissionPage({ location, data }) {
  const content = data.missionPage.frontmatter;
  const teamPhotos = data.teamPage.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroTeam
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        text={content.hero.heroText}
      />
      {content.blocks &&
        content.blocks.length > 0 &&
        content.blocks.map(block => {
          let contentHTML = remark()
            .use(recommended)
            .use(remarkHtml)
            .processSync(block.content)
            .toString();

          return (
            <Block className={`bloom-bg-white`} key={block.title}>
              <div className={`bloom-mb-5 bloom-flex`}>
                <h2
                  className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
                >
                  {block.title}
                </h2>
              </div>
              <div
                className={`bloom-prose`}
                dangerouslySetInnerHTML={{ __html: contentHTML }}
              />
            </Block>
          );
        })}
      {content.projects && (
        <Block>
          <div className={`bloom-mb-5 bloom-flex`}>
            <h2
              className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
            >
              Some of Our Work
            </h2>
          </div>
          <div className={`bloom-flex bloom-flex-wrap bloom--mx-2`}>
            {content.projects.project.map(project => {
              return (
                <div
                  className={`bloom-flex bloom-w-full md:bloom-w-1/2 lg:bloom-w-1/3 bloom-p-2 bloom-mb-4`}
                  key={project.title}
                >
                  <a
                    className={`bloom-card bloom-flex bloom-flex-col bloom-w-full`}
                    href={project.linkURL}
                  >
                    <div
                      className={`bloom-h-56 bloom-w-full bloom-bg-seafoam bloom-mb-2`}
                    >
                      <BackgroundImage
                        backgroundColor={`transparent`}
                        className={`bloom-h-56 bloom-w-full`}
                        fluid={project.image.childImageSharp.fluid}
                        Tag="section"
                      ></BackgroundImage>
                    </div>
                    <div className="bloom-p-6">
                      <h3
                        className={`bloom-text-2xl bloom-font-bold bloom-leading-tight bloom-uppercase bloom-mb-1`}
                      >
                        {project.title}
                      </h3>
                      <div
                        className={`bloom-font-bold bloom-text-green bloom-text-lg bloom-leading-tight bloom-mb-2`}
                      >
                        {project.client}
                      </div>
                      <div className={`bloom-prose bloom-mb-2`}>
                        {project.description}
                      </div>
                      <div className={`bloom-link`}>{project.linkTitle}</div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </Block>
      )}
      <Subfooter people={teamPhotos.people.person}>
        {/* <Block>
					<div
						className={`bloom-flex bloom-flex-col md:bloom-flex-row bloom-flex-wrap bloom-mx-2 bloom-py-6 bloom-items-center`}>
						<div className='bloom-pr-0 md:bloom-pr-8 bloom-mb-2 sm:bloom-mb-0 bloom-w-1/5 md:bloom-w-2/12'>
							<Img fluid={content.contracting?.image?.childImageSharp?.fluid} />
						</div>
						<div className='bloom-px-2 bloom-mb-2 sm:bloom-mb-0 bloom-w-full md:bloom-w-3/4'>
							<h2 className='bloom-text-3xl bloom-my-0'>Contracting</h2>
							<p className='bloom-text-lg bloom-mb-4 md:bloom-mb-2'>
								{content.contracting.description}
							</p>
							<a
								className='bloom-inline-block sm:bloom-block bloom-w-full md:bloom-w-2/5 bloom-mt-4 bloom-bg-green bloom-border-none bloom-text-white bloom-text-center bloom-p-5 bloom-leading-tight bloom-font-bold hover:bloom-bg-white hover:bloom-text-green'
								href='https://bloomworks.digital/contracting'>
								{content.contracting.ctaTitle}
							</a>
						</div>
					</div>
				</Block> */}
      </Subfooter>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MissionTemplate {
    missionPage: markdownRemark(frontmatter: { templateKey: { eq: "mission" } }) {
      html
      frontmatter {
        title
        subtitle
        blocks {
          title
          content
        }
        hero {
          heroText
        }
        contracting {
          ctaTitle
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
        projects {
          project {
            image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            client
            description
            title
            linkTitle
            linkURL
          }
        }
      }
    }
    teamPage: markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      html
      frontmatter {
        people {
          person {
            name
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  maxHeight: 800
                  duotone: {
                    highlight: "#ffffff"
                    shadow: "#cccccc"
                    opacity: 100
                  }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MissionPage;
